.gattex-logo {
    margin-bottom: 5px;
    margin-top: 12px;
}

.overview-img {
    border-radius: 100%;
    display: inline-block;

}

.overview-title {
    color: #00422D;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 3.5%;
    /* vertical-align: top; */
    margin-bottom: 6%;
}

.total-target-section {
    display: flex;
    align-items: center;
    margin-left: 15%;
    /* justify-content: flex-end; */
}

.total-target-1 {
    flex: 1;
}
.apexcharts-tooltip {
    color: #000 !important;
}


.total-target-1 h3 {
    margin-bottom: 0;
    color: #00422D;
}

.total-target-1 div {
    margin-bottom: 0;
    color: darkorange;
    font-weight: bolder;
    font-size: 24px;
}

.overview-kol-title {
    color: #00422D;
}

.overview-leaders-section {
    display: flex;
}

.overview-leaders-1 {
    flex: 1;
}

.amount-o {
    display: inline;
    color: darkorange;
    font-weight: bolder;
}

.overview-btn {
    background-color: #fff;
    border-radius: 16px;
    border: none;
    height: 25px;
    width: 130px;
    outline-style: none;
    border: solid 2px rgb(28, 90, 125, 1);
}

.overview-btn-section {
    text-align: center;
    margin-top: 5%;
}

.download-a {
    color: rgb(28, 90, 125, 1) !important;
    font-weight: bold;
}

.sticky-active-nav {
    position: sticky;
    top: 0;
    background-color: #fff;
}

.actives-header {
    border-bottom: #277D55 solid 1px;
    padding: 0 5% !important;
    /* width: 100%; */
    display: flex;
    align-items: center;
}

.actives-header img {
    flex: 0;
    /* position: absolute;
    top: 0;
    left: 0; */
    margin-left: -5%;
    margin-bottom: -3%;
}

.actives-header h3 {
    /* flex: 2; */
    margin-left: 2%;
    font-size: 24px;
    margin-bottom: 3%;
    margin-top: 5%;
}

.actives-header-lower {
    display: flex;
    border-bottom: lightgray solid 1px;
    background: #fff;
    justify-content: flex-end;
    padding: 2px;
    font-size: 16px;
}

.actives-header-lower > div {
    margin: 5px 8px;
}

.actives-hl-1 {
    margin: 5px 8px;
    font-weight: bolder;
}

.set-2 {
    min-width: 180px;
    max-width: 180px;
    white-space: nowrap;
}

.set-3 {
    text-align: right;
    margin-right: 4%;
    color: darkorange;
}


.active-row-1 {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin: 0 1rem;
    align-items: center;
}

.btm-border {
    border-bottom: 1px lightgray solid;
    width: 96%;
    margin-left: 2%;
}

.set-22 {
    min-width: 180px;
    max-width: 180px;
    /* white-space: nowrap; */
    margin-top: 1%;
}

.set-33 {
    /* margin-right: 7%; */
    color: darkorange;
    font-weight: bolder;
    font-size: 16px;
    /* margin-left: 5%; */
}

.set-34 {
    /* text-align: center; */
    margin-right: 10%;
    color: darkorange;
    font-weight: bolder;
    font-size: 16px;
    margin-left: 12%;

}

.section.dashboard .fw-normal {
	font-size: 14px;
}
#custom_box .custom_dot {
	position: relative;
	top: -2px;
}

/* TABS BOTTON SECTION */

.dot {
    height: 12px;
    width: 12px;
    background-color: rgb(28, 90, 125,1);
    border-radius: 50%;
    display: inline-block;
    /* margin: auto 0; */
    vertical-align: middle;
    margin-left: 4%;
}

.upcoming-info-1 {
    padding: 4px;
    border-bottom: 1px darkgray solid;
    vertical-align: middle;
}

.upcoming-info-name {
    vertical-align: middle;
    display: inline-block;
    margin-left: 8px;
   	cursor: default;
}


#meeting_event span.custom_dot, #name span.custom_dot
{
	 cursor: pointer;
}

.meetings-tab-container {
    background-color: white;
    width: 100%;
}

.meetings-tab-container h2 {
    padding: 7px 15px;
}

.meetings-tab-container button {
    text-align: center;
}

.cc-btn {
    /* margin: 0 auto; */
    background-color: rgb(28, 90, 125, 1);;
    border-radius: 16px;
    border: none;
    color: white;
    height: 30px;
    width: 130px;
    margin-top: 1.5%;
    outline-style: none;
    border-style: none;
}

.cc-btn:hover {
    cursor: pointer;
}

.tabs-header {
    padding: 0 1.5%;
    display: flex;
    align-items: center;
    margin-bottom: -16px;
}

.sticky-header-t1 {
    position: sticky;
    background-color: #fff;
    z-index: 100;
    top: 0;
    border-bottom: 1px solid rgb(28, 90, 125, 1);
}

.lower-tab {
    text-align: center;
    padding: 1rem;
    border-top: solid 1px rgb(28, 90, 125, 1);
    background-color: #fff;
    position: sticky;
    bottom: -1px;
}

.upcoming-date {
    text-align: left;
    padding: 2%;
    border-bottom: 1px darkgray solid;
    font-weight: bold;
    font-weight: bolder;
    font-size: 17px;
}

/* SIDE NAV */

.home-account-box {
    padding: 6%;
    text-align: center;
    background-color: rgb(219, 234, 227);
}

.profile-img {
    border-radius: 50%;
}

.first-last-name {
    font-weight: bolder;
    color: #277D55;
}

.edit-account-btn {
    margin-top: 4%;
    background-color: #fff;
    border: #277D55 solid 1px;
    border-radius: 16px;
}

.f-color-g {
    font-weight: bolder;
    color: #1D5A7D;
    font-size: 85px;
}

.active-profile-img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 0px;
    margin-right: -3%;
}

/* EVENTS TILES */
.events-tile-1 {
    border-bottom: lightgray 1px solid;
    padding: 2%;
}

.events-name-1 {
    font-weight: bolder;
    font-size: 17px;
}

.events-date-1 {
    color: #737373;
    font-size: 17px;
}

.cal-img {
    float: right;
    /* vertical-align: middle; */
    margin-top: 13px;
    margin-right: 5px;
}

.engagement-title {
    color: #10422E;
    white-space: nowrap;
    font-size: 20px;
    margin-left: -5px;
    /* margin-bottom: -5px; */
    margin-bottom: 5%;
}

.engagement-header {}

.t2 {
    border-bottom: rgb(28, 90, 125, 1) solid 1px;
}

/*  ENGAGEMENTS TILE(S) */
.engagement-body {
    display: flex;
    justify-content: space-between;
    padding: 0% 2.5%;
    border-bottom: lightgray solid 1px;
}

.engagement-header-1 {
    margin-bottom: 1.5%;
    margin-top: 1.5%;
    font-size: 20px;
    color: #008A5E;
    flex: 1;
}

/* KOL ARROW*/

.kol-arrow {
    color: #008A5E;
    font-size: 20px;
    margin-left: 1%;
}

.card-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.card-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
    text-align: center;
}

.card-button {
    border-radius: 50px !important;
    text-transform: inherit !important;
    min-width: 150px !important;
    padding: 3px 16px !important;
    font-weight: initial !important;
}

.save-button {
    border-radius: 50px !important;
    text-transform: inherit !important;
    min-width: 90px !important;
    margin-left: 0.7rem !important;
}

/* .MuiSelect-select.MuiSelect-select {
    padding-right: 15px !important;
} */
body{
font-family: Bilo !important;	
}
/*#menu_outer span {
	font-size: 15px;
	font-weight: 600;
	color: #05357c;
	font-family: Bilo;
}*/

header .makeStyles-toolbarIcon-7 {
	height: 60px;
	background: #fff;
	min-height: 60px;
}

header .MuiButton-label {
	font-size: 13px;
	font-weight: bold;
	padding-bottom: 10px;
	color: #fff !important;
	text-transform: capitalize;
	font-family: Bilo;
}

header .makeStyles-button-24 {
	font-size: 13px;
	font-weight: bold;
	color: #1c5a7d !important;
	padding: 0;
	border: none;
	margin-right: 0;
}
.home-title {
    text-decoration: none;
    color: inherit;
}
.makeStyles-hamburger-26 {
	border: none;
	padding: 0;
	margin-left: 0;
	margin-right: 16px;
	color: #1c5a7d !important;
	border: none!important;
}


header button:hover {
	background-color: transparent !important;
}
#custom_page {
	max-width: 100% !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-top: 130px;
}
#custom_page .MuiBox-root #heading_strip span {
	padding: 10px 15px;
	font-size: 18px;
	font-weight: 700;
	color: #000;
	font-family: Bilo;
	margin:0;
	line-height: 1.2;
}
#status_list div {
	color: #000;
}
span.dot.bg-success.me-2 {
    background: #46aa6a !important;
}
span.dot.custom_blue.me-2 {
    background: #f79f24;
}
span.dot.bg-info.me-2 {
    background: #54bdf3;
}

body .apexcharts-text tspan ,body .apexcharts-legend-text{
	font-family: Bilo !important;
	font-size: 12px;
	color:#000 !important;
}
.cricle_chart_outer .apexcharts-canvas .apexcharts-legend.apexcharts-align-center.position-bottom {
	margin: 15px auto 0;
	text-align: left;
	justify-content: flex-start;
}
.cricle_chart_outer #custom_box .apexcharts-canvas .apexcharts-legend.apexcharts-align-center.position-bottom .apexcharts-legend-marker{
	border-radius: 0 !important;
}

.cricle_chart_outer #custom_box .apexcharts-canvas .apexcharts-legend.apexcharts-align-center.position-bottom .apexcharts-legend-series {
	width: 45%;
	
}
.cricle_chart_outer .apexcharts-canvas {
/*    padding-top: 40px;*/
}


#custom_page #custom_box #apexcharts5t4lm02t .apexcharts-legend.apexcharts-align-center.position-bottom {
	justify-content: flex-start;
	margin: 0 auto;
}
#custom_page .MuiPaper-rounded {
	margin-bottom:0;
	border-radius: 0;
	box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1) !important;
	border: 1px solid #d9dfe8;
}
#custom_page #heading_strip {
	background: transparent;
	padding: 0;
}
#custom_page .makeStyles-fixedHeight-68 {
	height: auto;
	min-height: 240px;
}
#heading_inner {
	padding: 0;
	border-bottom: 1px solid #43608d33 !important;
}
#total_value {
	font-weight: 700;
	font-size: 32px;
	color: #000;
	margin-bottom: 10px;
	line-height: 1.2;
	font-family: Bilo;
}
#blue_label_outer{
	margin-bottom: 15px;
	margin-top: 30px;
}
body #inner_box span,body #blue_label {
	font-weight: 700;
	font-size: 18px;
	color: #000;
	line-height: 1.2;
	font-family: Bilo;
}

#blue_label_outer {
	padding-top: 0;
}
#blue_label_outer {
	padding-top: 0;
}

#status_list .MuiGrid-root.MuiGrid-item {
	font-size: 14px;
	color: #000;
	font-weight: normal;
	font-family: Bilo;
}

#status_list  .makeStyles-boldNumbers-84 {
	color: #ff8c00;
	font-family: Bilo;
}
#custom_page .MuiButton-containedPrimary {
	color: #fff;
	background-color: #05357c;
	font-family: Bilo;
	font-size: 14px;
	padding-right: 1rem !important;
	padding-left: 1rem !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	border-radius: 5px !important;
	font-weight: 500 !important;
}
#custom_page .makeStyles-boldNumbers-85{
	color: #000;
	font-weight: 700;
}
#blue_btn {
	margin-bottom: -5px;
}

#menu_outer .MuiListItem-gutters:hover {
	background: rgba(163, 187, 200, 0.3);
}
#menu_outer .MuiListItem-gutters {
	padding: 10px 15px !important;
}
#menu_outer {
	border-top: 1px lightgray solid;
}
/*#menu_outer span {
	font-size: 15px;
	font-weight: 600;
	color: #05357c;
	font-family: Bilo;
}*/
#nav_sec {
	padding-top: 0;
}

#nav_sec .makeStyles-sideItems-40 {
	border-bottom: none;
}

#menu_outer .MuiCollapse-wrapperInner {
	width: 100%;
}
#menu_outer .MuiCollapse-wrapperInner a {
	padding: 7px 15px !important;
	border-bottom: 1px solid #43608d33;
}
#year_start {
	padding: 15px 15px 0;
}
#custom_page #heading_strip #tittle_tabs span.MuiTabs-indicator {
	height: 4px;
	padding: 0;
	z-index: 1;
}
#tittle_tabs button {
	padding: 0;
}

#custom_page .MuiBox-root #heading_strip #tittle_tabs span {
	padding: 0;
	text-transform: uppercase;
	font-weight: 700;
	font-family: Bilo;
	font-size: 14px;
	color: #737373
}

#custom_page .MuiBox-root #heading_strip #tittle_tabs .MuiTab-textColorPrimary.Mui-selected span {
	color: #05357c;
}
#custom_page .MuiBox-root #heading_strip #tittle_tab .makeStyles-greenBorder-185 {
	background-color: transparent;
	border-bottom: 2px solid #ebeef4;
	position: relative;
	top: -3px;
	z-index: 0;
}
#custom_box .recharts-wrapper {
	padding: 0 15px;
}

#custom_box .lower-tab {
     border-top: none;
	 position: inherit; 

}
#custom_box .makeStyles-fixedHeight2-75 {
	height: 490px;
}
#custom_page .PrivateTabIndicator-colorPrimary-210 {
	background-color: #05357c;
}


#divider_line {
	position: relative;
	top: -2px;
	background-color: #ebeef4;
	height: 2px;
}

#custom_box .custom_dot {
	border: none;
}
#custom_page #text_label {
	padding: 0;
/*	text-transform: uppercase;*/
	font-weight: 700;
	font-family: Bilo;
	font-size: 16px;
	color: #000;
}
#footer_outer {
	margin-bottom: 0;
	padding: 20px 0;

	transition: all 0.3s;
}
#footer_outer p {
	font-family: Bilo;
	font-size: 14px;
	color: #000;
	padding: 0 15px;
}
#focus_content,#custom_page .MuiPaper-rounded {
	overflow: inherit !important;
}
#custom_box {
	overflow-x: auto;
}
#side_bar_account {
	background: transparent;
	min-height: 60px;
	height: 60px;
	justify-content: flex-start;
}
#side_bar_account svg {
	fill: #04347c;
	width: 35px;
	height: 35px;
}

#side_bar_account .MuiButtonBase-root.MuiIconButton-root {
	font-size: 28px;
	cursor: pointer;
	/*border: 3px solid #000;*/
	/*margin-right: 8px;*/
	/*border-radius: 100%;*/
	line-height: 1.5;
	height: 35px;
	width: 35px;
	color: #000;
	display: flex;
	align-items: center;
}
#side_bar_account_detail {
	background: #f4f5f7;
	border-top: 1px solid #43608d33;
	/*	border-bottom: 1px solid #43608d33;*/
	text-align: center;
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
	display: none;
}
#name_title {
	color: #1a5a7d;
	font-size: 14px;
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2;
}
#email_id {
	font-weight: 600;
	color: #9a9a9a;
	font-size: 14px;
	line-height: 1.2;
}
#nav_sec .MuiListItemText-root.f-color-g {
	margin: 0;
}
#profile_img {
	width: 120px;
	height: 120px;
	margin: 0 auto 1rem !important;
}
#profile_img svg{
	width: 120px;
	height: 120px;	
}
#side_bar_outer_sec .MuiDrawer-paper {
	width: 300px;
}
#header_outer.makeStyles-appBarShift-22 {
	width: calc(100% - 300px);
}
#header_outer{
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
	background: #05357c;
}
/* Card */
.card {
  margin-bottom: 20px;
  border-radius: 0;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #d9dfe8;
	background: #fff;
}
.card-body {
	padding: 0 15px 15px 15px;
}
.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  font-family: Bilo;
  margin: 0 -15px 10px -15px;
	font-weight: bold;
}
.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}
.card-body {
  padding: 0 15px 15px 15px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
.brdr-btm-gray {
	border-bottom: 1px solid #43608d33 !important;
}
.section.dashboard table {
	width: 100%;
	margin-bottom: 1rem;
	vertical-align: top;
	border-color: #dee2e6;
	color: #000;
	caption-side: bottom;
	border-collapse: collapse;
	font-size: 0.875rem;
	line-height: 1.43;
}
.section.dashboard table  th{
	color: #737373;
}
.section.dashboard tr td:last-child,.section.dashboard tr th:last-child{padding-right: 12px;}
.section.dashboard table .text-primary {
	color: #000 !important;
}

.section.dashboard .table thead {
	background: #f8f9fb;
}
.section.dashboard .card-title {
	margin: 0;
	box-sizing: border-box;
}
.section.dashboard .fw-normal {
	color: #000;
}
.section.dashboard  .card-body {
	padding: 0 0 15px 0;
}
.section.dashboard .row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-.5 * var(--bs-gutter-x));
	margin-left: calc(-.5 * var(--bs-gutter-x));
}
.section.dashboard  .text-primary {
	color: #1c5a7d !important;
}

.section.dashboard tr td:last-child , 
.section.dashboard tr th:last-child {
  text-align: right;
}

.card {
	border-radius: 0;
	box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #d9dfe8;
}

#top_heading_stip.m-n-title {
	margin: -20px -20px 20px -20px !important;
}
#top_heading_stip.light-bg {
	background: #fcfcfc !important;
	box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #d9dfe8;
	flex-direction: inherit;
	align-items: center;
}
#top_heading_stip h1 {
	font-size: 24px;
	margin-bottom: 0;
	font-weight: bold;
	color: #000;
	font-family: Bilo;
}
.btn_right_label {
	display: flex;
}
.filters_btn_sec {
	margin: 0 15px;
	padding: 0 15px;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
}
.p-20 {
	padding: 20px !important;
}
.dash-items {
	font-size: 13px;
	display: flex;
	align-items: center;
}

.dia {
	display: flex;
	align-items: center;
	white-space: nowrap;
}
.me-4 {
	margin-right: 1.5rem !important;
}
.menu_name:hover {
	background: #f0f0f0;
}
.menu_name.black_link:hover,.menu_name.log_out {
	background: transparent;
}
.brands-tag {
	padding: 4px 8px;
	color: #fff;
	font-weight: bold;
	/*border: 1px solid #000;*/
	font-size: 12px;
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
}
.bt-row {
	display: flex;
	align-items: center;
	justify-content: center;
}
.custom_dot {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #fff;
}
.custom_green{
	background: #46aa6a;
}
.custom_org{background: #f79f24;}

.custom_blue{background: #f39f54;}
.custom_info{background: #54bdf3;}

.header_form-select .form-select {
	height: auto;
	background: transparent;
	border: none;
	color: #fff;
	font-size: 14px;
	border-bottom: 1px solid #fff;
	border-radius: 0;
	padding: 0;
	outline: none !important;
	box-shadow: none !important;
	position: relative;
}
.select_inner_box {
	position: relative;
	display: inline-block;
}

.select_inner_box:before {
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-right-style: solid;
	border-right-width: 2px;
	content: "";
	display: inline-block;
	height: 8px;
	right: 0;
	position: absolute;
	top: 2px;
	width: 8px;
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.MuiGrid-root.brand-strip-sec {
	display: none;
}

.header_form-select .form-select option {
	color: #000;
}

.header_form-select {
	padding: 0px 0 0 20px;
	margin-left: 15px;
	position: relative;
}

.header_form-select::after {
	content: "";
	position: absolute;
	left: 0;
	top: -4px;
	height: 30px;
	background: #fff;
	width: 1px;
}
#custom_page .meeting_box_outer #custom_box li,#custom_page .event_box_outer  #custom_box li {
	font-weight: 600;
	color: #000;
	font-size: 16px;
}
#custom_page .meeting_box_outer .dot,
#custom_page .event_box_outer .dot{
	margin-left: 0;
	background: #46aa6a;
}
#custom_page .leaderboard_box_outer .react-tabs__tab span.custom_dot  {
	border: none;
}
.most_active_outer .MuiPaper-root:not(:last-child) {
    border: 1px solid rgba(0, 0, 0, .125) !important;
}

.meeting_box_outer #heading_inner,.event_box_outer #heading_inner {
	border-bottom: none !important;
	margin-bottom: -10px;
}
.new_label strong {
	font-weight: 500;
	font-size: 14px;
}
.new_label label {
	font-weight: 500;
	margin-left: 5px;
}
.di-date-range select {
	font-size: 14px;
	font-weight: bold;
	height: 30px;
	line-height: 1;
}
.form-select {
	display: block;
	width: 100%;
	padding: .375rem 2.25rem .375rem .75rem;
	-moz-padding-start: calc(0.75rem - 3px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right .75rem center;
	background-size: 16px 12px;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	appearance: none;
	word-wrap: normal;
}
.di-view .form-check {
	min-height: auto;
	margin: 0;
	padding-left: 1.5em;
	display: block;
}
.upcoming-info-name {
	font-weight: normal;
	font-size: 14px;
}
.over_view_tabs .MuiPaper-rounded:last-of-type {
	margin-top: 20px;
}

#custom_page .cstm-tabs .react-tabs__tab-list ,#custom_page .leaderboard_box_outer .react-tabs__tab-list{
	display: flex;
	justify-content: space-between;
}

#custom_page .cstm-tabs .react-tabs__tab.react-tabs__tab--selected,#custom_page .leaderboard_box_outer .react-tabs__tab.react-tabs__tab--selected {
	background-color: #fff;
	color: #05357c;
	border-bottom: 4px solid #05357c;
}

#custom_page .cstm-tabs .react-tabs__tab,#custom_page .leaderboard_box_outer .react-tabs__tab {
	width: 100%;
	border: none;
	color: #737373;
	border-radius: 0;
	text-align: center;
	font-size: 14px;
}
#custom_page .leaderboard_box_outer .MuiBox-root #heading_strip .dot.bg-primary.me-1{background:#46aa6a !important;}
#custom_page .cstm-tabs .react-tabs__tab::after,.leaderboard_box_outer .react-tabs__tab::after{
	display: none;
}
#custom_page .leaderboard_box_outer .react-tabs__tab span{
	padding: 0 !important;
}

.row_outer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 0 12px;
}

#custom_page #custom_box {
	overflow: inherit;
}



.most_active_outer #blue_label {
	color: #737373;
	font-size: 16px;
	font-weight: 400;
}

.most_active_outer ul.MuiList-root.MuiList-padding li {
	border: none !important;
	height: inherit;
}

.most_active_outer .MuiPaper-root {
	/*border: none !important;*/
}

.most_active_outer .MuiAccordionSummary-root {
	border: none !important;
	margin: 0 !important;

}

.most_active_outer #heading_inner {
	border: none !important;
}

.most_active_outer .makeStyles-lightBoldText-133 {
	color: #f79f24 !important;
	font-family: Bilo;
	font-size: 14px;
	font-weight: bold !important;
}

.most_active_outer .makeStyles-dropDownHeader-134 {
	font-weight: 700;
	font-size: 18px;
	color: #1c5a7d;
	line-height: 1.2;
	font-family: Bilo;
}

.most_active_outer .MuiGrid-item {
	margin: 0;
	box-sizing: border-box;
	/*color: gray;*/
	font-weight: 700;
	font-size: 13px;
	color: #000;
}
.most_active_outer .MuiGrid-item:first-of-type{
	font-weight: 500;
}
.most_active_outer .dot.bg-primary,.leaderboard_box_outer .dot.bg-primary {
	background: #05357c !important;
}

.most_active_outer .dot.bg-warning,.leaderboard_box_outer .dot.bg-warning {
	background: #f39e55 !important;
}

.most_active_outer .dot.bg-info,.leaderboard_box_outer .dot.bg-info  {
	background: #54bdf3 !important;
}

.most_active_outer .MuiListItemAvatar-root {
	min-width: 50px;
}

.most_active_outer .MuiListItem-button:hover {
	text-decoration: none;
	background-color: transparent;
}

.most_active_outer .react-tabs__tab-panel {
	min-height: 475px;
	overflow-y: auto;
	max-height: 475px;
	background: #fff;
}

.least_active_outer .makeStyles-fixedHeight-74 {
	height: auto;
}

.over_view_tabs .makeStyles-fixedHeight-74 {
	max-height: 270px;
}

#custom_page .over_view_tabs .MuiButton-containedPrimary {
	min-width: 117px !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	box-shadow: none !important;
}
#custom_page .over_view_tabs .MuiButton-containedPrimary:hover{
color: #fff;
background-color: #2d78a3;
border-color: #2d78a3;
}

.section.dashboard .card-body table.bg-warning {
	background-color: #f39e55 !important;
}

.most_active_outer.least_active_outer .react-tabs__tab-panel {
	padding-bottom: 20px;
	min-height: inherit;
}

.section.dashboard .card {
	margin-bottom: 0;
}
#custom_page .leaderboard_box_outer .react-tabs__tab-list {

	padding-top: 10px;
}
.most_active_outer #react-tabs-9,.most_active_outer #react-tabs-11 {
	padding:0;
}
#outer_pop {
	max-width: 500px;
	border: 1px solid rgba(0,0,0,.2) !important;
	border-radius: .3rem !important;
	background-color: #fff !important;
	background-clip: padding-box !important;
	padding: 0 !important;
}
.modal_header {
	padding: 40px 40px 0;
	/*border-bottom: 1px solid #dee2e6;*/
	border-top-left-radius: calc(.3rem - 1px);
	border-top-right-radius: calc(.3rem - 1px);
	justify-content: space-between;
	display: flex;
	
}
.modal_body {
	padding: 1rem 40px;
	position: relative;
	width: 100%;
	display: inline-block;
}
.modal_header  .btn {
	padding: 0 6px;
	line-height: 1;
	outline: none;
	border: none;
}
.modal_header h2 {
	font-size: 22px;
	color: #000 !important;
	margin: 0;
	font-weight: bold;
}
.modal_footer {
	padding: .75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(.3rem - 1px);
	border-bottom-left-radius: calc(.3rem - 1px);
}
#outer_pop .rdrDateDisplayWrapper {
	background-color: #fff;
}
#header_outer .makeStyles-alert-58 {
	font-size: 13px;
	font-weight: bold;
	padding-bottom: 0;
	color: #fff !important;
	text-transform: capitalize;
	font-family: Bilo;
	border-right: 1px solid #ddd;
	margin-right: -25px;
	padding-right: 10px;
}
header .MuiButton-label {
	margin: 0;
	padding: 0;
}
#header_outer .makeStyles-toolbar-20 {
	padding-right: 15px;
	padding-left: 15px;
}
#header_outer #burger_menu {
	color: #fff !important;
	border: none;
}
.cstm-tabs .nav-tabs-bordered .nav-link:hover, .cstm-tabs .nav-tabs-bordered .nav-link.active {
	color: #05357c;
}
#outer_pop .rdrCalendarWrapper.rdrDateRangeWrapper {
	width: 100%;
}
#outer_pop .modal_body {
	padding: 1rem 40px;
}
#header_outer button:last-of-type {
	border: none;
}
#header_outer button.jss22 {
	padding-bottom: 0;
	line-height: 1;
}
/*
#outer_pop .rdrMonths.rdrMonthsVertical {
	border: 1px solid #ccc;
	border-radius: 0 0 10px 10px;
}
*/


.custom_chart_sec p.impactscore-method {
    font-size: 12px;
    font-weight: 400;
    margin: 0 5% 5px 0;
}
.custom_chart_sec h3 {
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #ccc6;
    padding-bottom: 5px;
    width: 95%;
    margin: 0 0 10px;
}
span.score_icon {margin-left: 8px;position: relative;top: -2px;}
.custom_chart_sec span.engagement-method {
    font-weight: 600;
}
.custom_chart_sec   span.engagement-method.gry {
    color: #727272;
}

.detail_list{
	height:205px;
}



#outer_pop .rdrWeekDay {
	color: #000 !important;
	margin: 0;
	font-weight: 600;
	font-size: 14px;
}
#outer_pop .rdrDay span {
	font-size: 14px;
	font-weight: 600 !important;
}
#outer_pop .rdrMonth {
	width: 100%;
	padding-bottom: 0;
}

#outer_pop .modal_header .btn {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 33px;
}
#outer_pop .rdrDateDisplayItem input {
	border: 1px solid #ccc;
	color: #000;
	font-size: 13px;
	text-align: left;
	padding:5px 15px;
	outline: none !important;
	font-family: Bilo !important;
	font-weight: 600;
	border-radius: 5px;
}
#outer_pop .rdrDateDisplayItem {
	box-shadow: none;
	border: none;
}
#black_label ,.black_label{
	color: #000;
	font-weight: 600;
}
#custom_page .MuiBox-root #heading_strip #tittle_tabs span.MuiTabs-indicator{
	background-color: #05357c;
}

.filter_label_style .di-view.dia {
	color: #000;
	font-family: Bilo !important;
}
.filter_label_style strong {
	font-weight: bold;
	font-size: 14px;
	color: #000;
}
.filter_label_style .form-select:focus {
	border-color: #ccc;
	outline: 0;
	box-shadow: none;
}
.form-check .form-check-input[type="checkbox"] {
	width: 15px;
	height: 15px;
	border-radius: 3px;
}
.filter_label_style .di-view.dia {
	border-right: 1px solid #ccc;
	margin-right: 15px;
	padding-right: 15px;
}
.btn.confirm_btn {
	color: #fff;
	background-color: #05357c;
	font-family: Bilo;
	font-size: 14px;
	padding-right: 1rem !important;
	padding-left: 1rem !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	border-radius: 5px !important;
	font-weight: 500 !important;
}
.confirm_btn_outer {
	width: 100%;
	display: inline-block;
	text-align: center;
	padding-bottom: 30px;
}
#alert_btn {
	display: none;
}
.custom_btn img {
	max-width: 14px;
	margin-left: ;
	margin-right: 2px;
}
.disabled{
	  pointer-events: none;
}
.leaderboard_box_outer .react-tabs__tab {
	padding: 6px 6px;
}
.menu_name a{
    color: #05357c;
	text-decoration: none;
}
.menu_name {
	padding: 10px 15px;
	font-weight: 700 !important;
	font-size: 16px !important;
	color: #05357c;
	font-family: Bilo !important;
}
#menu_outer span{
	font-weight: 700 !important;
	font-size: 16px !important;	
	color: #05357c;
	font-family: Bilo !important;
}
.menu_name.black_link span{
	color: #000 !important;
}
.menu_list.boder_line {
	border-top: 1px solid #ccc;
	padding: 5px 0;
	border-bottom: 1px solid #ccc;
}
.menu_list.boder_line.border-top-none{
	border-top: none;
}
#menu_outer .MuiListItem-gutters{
	border-color: #ccc !important;
}
.menu_name.log_out {
	text-align: center;
	padding-top: 60px;
	padding-bottom: 20px;
}
#side_bar_outer_sec hr {
	display: none;
}
.menu_name.sub_link {
	padding-left: 30px;
}
#menu_outer .MuiListItem-gutters:hover {
	background: transparent;
}
.menu_name.log_out span {
	cursor: pointer;
}
.drop_down_input {
	width: 100%;
	padding: 10px 15px;
	font-size: 14px;
	border-radius: 5px;
	background: #fff;
	border: 1px solid #ced4da;
	color: #000;
	text-transform: capitalize;
	min-width: 190px;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
	background-position: center right 15px;
	background-size: 15px;
	background-repeat: no-repeat;
	cursor: pointer;
}
.custom_drop_down {
	width: 100%;
	display: inline-block;
	position: relative;
}
.select_list {
	position: absolute;
	background: #fff;
	left: 0;
	right: 0;
	z-index: 1;
	top: 40px;
	border-radius: 10px;
	box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #ced4da;
	cursor: pointer;
}
.select_list ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.select_list ul li{
	padding: 15px;
	border-bottom: 1px solid #ced4da;
	color: #000;
	font-size: 16px;
}
.select_list ul li:last-of-type{
	border: none;
}
#header_outer .custom_dot {
	position: relative;
	top: -1px;
}
.site-footer__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.custom_date_range {
	display: none;
}


.Overview_tabs_outer .MuiPaper-root.MuiPaper-rounded {
    height: auto;
}
.Overview_tabs_outer .MuiPaper-root.MuiPaper-rounded div#total_value {
    margin-bottom: 0;
}

.Overview_tabs_outer .MuiPaper-root.MuiPaper-rounded div#blue_label_outer {
    margin-top: 15px;
}

.Overview_tabs_outer  .num_top_space {
    padding-top: 15px;
}
.most_active_outer .MuiPaper-rounded {
    height: 553px !important;
}

section.section.dashboard .card-body {
    min-height: 200px;
}
div#focus_content [role="separator"] {
    display: none;
}
#focus_content .MuiGrid-grid-xs-6 {
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
}
#focus_content ul .MuiBox-root{
	position: relative;
}
#focus_content ul .MuiBox-root::after {
    content: "";
    position: absolute;
    left: -24px;
    right: -24px;
    top: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
#custom_page .MuiBox-root #heading_strip #focus_content span{
	font-size: 14px;
	font-weight: 400;
	padding: 7px 15px;
}
.leaderboard_box_outer #heading_strip .MuiDivider-root {
	background-color: transparent;
	position: relative;
	top: -15px;
	opacity: 1;
	height: auto;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.meeting_box_outer #divider_line,.event_box_outer #divider_line {
    position: relative;
    top: -1px;
    background-color: transparent;
    height: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    opacity: 1;
}
.sort_icons .btn {
	outline: none;
	box-shadow: none;
}
.notes_outer .MuiCardContent-root ,.Connections_outer .MuiCardContent-root{
	height: auto !important;
	min-height: 300px;
}
#custom_page table td,#custom_page table th {
	color: #000;
}


#education_box table td{
border-bottom: 1px solid #c0c0c0;
padding: 8px 24px 8px 16px;
}
.coming_soon p {
	padding: 14px 36px;
	font-size: 0.875rem;
	font-family: Bilo;
	font-weight: 400;
	line-height: 1.43;
	color: #05357c;
	border: 1px solid #05357c;
	display: inline-block;
	justify-content: center;
	margin: 0;
}
.coming_soon {
	display: flex;
	justify-content: center;
}
.notes_popouter #form-dialog-title h2 {
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	position: relative;
}
.notes_popouter form {
	padding: 0 24px;
	width: 100%;
	display: inline-block;
}
#form-dialog-title.notes_popinner {
	padding-top: 15px;
}
.notes_popouter .MuiDialog-paperWidthSm {
	max-width: 550px;
	width: 100%;
}
.notes_popouter .MuiBox-root{
width: 100%;
	display: inline-block;	
}
.hidden_class{
	display: none;
}
.notes_popouter #form-dialog-title h2 button {
	padding: 0;
	min-width: inherit;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAROQAAETkBG9mTRgAAAMBQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACzsvEAAAAEB0Uk5TADO07eOfC+Q16//WISy7x9f33iP28M42sf4W4hfVLekS6jDY2UQfxsU6ICnS388NDKXs+fjEQvNFyv23GLbLRlWGYZIAAAIfSURBVHiclZbZWsIwEIVHBYW2IFJEcCm1AqLghruivv9bidJmJvtkrkLS/8s0J6cHgK3tnVp9F4Jqt763s70F0GhG64qTELYV/zHNBrSj/9oPoJPOhmnDwWYQdVMum3ZLpAeH5SjqH/HYo35FHMKgGg77rL3T/rAiBnB8Uo2jLuO9k654/OR0fXRn4men5WNbHfFwnP1PjJDO3WyO7Hm2mSoukHZ2niA7LqrJCdL7Uzs7vUR2gtMFdj6znnk6w54LutASZ25VjGgUZ/LSFdIzY+fTmWBrmbaIepvueYLvW7vWl6neubqYq/qqNV9YFSN3YzE3sZLecudGfTUa96YOTfE+L6zsunN8b3QoejCKLT2XL1fT9Cb66hrJNRV06VDiQZNGyt6yQzUPemjqUIMH3VWMkeZoJNcEaWQnfq7ce6Sy58x9/woV458V1pVE+/RVi+jN0FcpolF0kYex5G5Evm+qWonEhmUo8WBZN+wMJR4UdcvMUOpBdOgta2/Jg5pD3aV4MChDNQ8GZKjhO0kd6uzcmIPEoa4MteQgL0NtOWj4pmo923MQHWrJUGcOEr1N99yTg84M9eagI0MZOUj0ljtn5aAlQ5k5aMxQdg4a9A7IQc2hQTmoOPQuFj85OZgRh97Dg1cjuYhiD7AULDMH0aFLeKx6ZuegcOgTPHPPCqtS7BleXv36qrX5l/v6AvD2/tH7XIWwAKuv3sf3D/wCk19QAnT1FawAAAAASUVORK5CYII=);
	width: 18px;
	height: 18px;
	background-size: contain;
	background-repeat: no-repeat;
	position: relative;
    top: 4px;
    right: -15px;
	font-size: 0;
}

.add_notes_btn {
	width: 100%;
	display: flex;
	justify-content: center;
}
.add_notes_btn button {
	background: #04347c !important;
	border-color: #04347c !important;
	color: #fff;
	position: relative;
	top: -5px;
	margin-bottom: 10px;
}
.notes_popouter hr{
	background: #bfbfbf !important;
}
#custom_page .top_strip .btn_right_label div .MuiButtonBase-root.MuiButton-root.MuiButton-contained.card-button.MuiButton-containedPrimary {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAMnAAADJwBk4+WmgAAAC1QTFRFAAAA////////////////////////////////////////////////////////oUirmwAAAA90Uk5TABeq8RQR1f/NYomNjuNfUjyWdwAAAHBJREFUeJxjYIACRmUjBwZ0EFZe3oohOL28vBJDcHl5eRWG4Pby8uoRIHi8vLwGzmGdtRsE9lwvL689DWauC2AQK8cAiQzqmIJFDOaYgsXYBbFq98AUbMHuJKyOHxzhSTdBrIn2eXl5HYYg1oyAnGUAPFWPtVLSECAAAAAASUVORK5CYII=) !important;
	background-repeat: no-repeat !important;
	background-size: 15px 15px !important;
	background-position: center left 15px !important;
	padding: 14px 15px 14px 35px !important;
}
.dummy_placeholder {
	position: absolute;
	left: 17px;
	top: 13px;
}
.placeholder_label {
	position: relative;
}

.placeholder_label::before {
	content: "Select Engagement Type";
	position: absolute;
	top: 13px;
	font-size: 14px;
	left: 15px;
}
.custom-select.custom_label .MuiBox-root {
	margin: 0;
}


.custom_popup_outer .MuiDialogContent-root.MuiDialogContent-dividers {
	overflow-x: hidden;
	width: 100%;
}
.custom_popup_outer .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,.custom_popup_outer .msl-grp-title,.custom_popup_outer .msl-options > .msl-option {
	font-size: 14px;
	font-family: Bilo !important;
}
.custom_popup_outer .MuiGrid-spacing-xs-2 > .MuiGrid-item{
	justify-content: flex-start;
display: flex;
}
.required_label {
	text-align: left;
	margin-top: 15px;
}
#focus_tabs {
	overflow-x: hidden;
}
#custom_page .MuiBox-root #heading_strip #focus_content span{
	padding: 7px 0px;
}
#focus_tabs {
	overflow-y: auto;
	max-height: 425px;
}
.leaderboard_box_outer #heading_strip .MuiDivider-root {
	display: none;
}
#custom_page .leaderboard_box_outer .MuiPaper-rounded {
	min-height: 545px;
}
#custom_page .MuiBox-root #heading_strip #focus_tabs span {
	padding: 10px 5px;
}
#focus_tabs ul {
	overflow: hidden;
	padding: 0;
	margin: 0 -7px;
}
#custom_page #focus_tabs #text_label {
padding-bottom: 10px;
}
.meeting_box_outer .MuiPaper-rounded {
	height: 548px;
}
#footer_outer p {
	max-width: 880px;
	margin: 0 auto;
}
.report_table_list th{
	font-size: 16px;
	color: #000000;
	font-family: Bilo;
	font-weight: bold;
	padding:5px; 
	padding-bottom: 10px;
}
.report_table_list td{
	font-size: 16px;
	font-weight: 400;
	color: #000000;
	font-family: Bilo;
	padding:5px; 
}
.report_table_list td:last-of-type img {
	max-width: 30px;
}
.report_table_list table {
	width: 100%;
	display: table;
}
.report_table_list th:first-of-type,.report_table_list td:first-of-type{
	width: 30%;
	text-align: left;
}
.report_table_list th:last-of-type,.report_table_list td:last-of-type{
	width: 20%;
	text-align: right;
}
.report_table_list th,.report_table_list td{
	width: 50%;
	text-align: left;
}
.report_table_list tbody tr {
	border-top: 1px solid rgba(0, 0, 0, .125) !important;
}
.new_select_sec {
	display: flex;
	flex-wrap: wrap;
}
.new_select_outer {
	width: 100%;
	display: flex;
}
.left_select {
	width: 100%;
	margin-bottom: 20px;
}
.right_select {
	width: 100%;
	display: flex;
	align-items: center !important;
}
.left_select {
	display: flex;
	align-items: center !important;
}
.new_select_outer label {
	margin-right: 10px;
	font-weight: bold;
	color: #000;
	font-size: 16px;
}
.report_table_list button {
	border: none !important;
	background: transparent !important;
}
.new_select_outer .form-select svg {
  display: none;
}



@media (min-width: 1200px) {
	.header_select{
		min-width: 220px;
		max-width: 220px;
	}
.kolAttendeealign .css-gj1fbr-MuiGrid-root {
	max-width: 25.333333%;
}

.kolAttendeealign .MuiGrid-root{
	flex-basis: 27%;
	max-width: 27%;
}
}

@media (min-width: 768px) {
	.detail_brand_outer .detail_list {
		min-height: 205px;
	}
.new_select_sec {
	justify-content: flex-end;
	padding-right: 150px;
	margin-bottom: -80px;
}
.new_list_style div {
	align-items: flex-end;
}
.left_select {
	width: 50%;
	padding-right: 15px;
	margin-bottom: 0px;
}
.right_select {
	width: 50%;
	padding-left: 15px;
}
.new_select_outer {
	width: 50%;
	display: flex;
	max-width: 600px;
}
.cricle_chart_outer .apexcharts-canvas .apexcharts-legend.apexcharts-align-center.position-bottom {
/*	width: 350px !important;*/
}
.select_list {
	left: -160px;
    min-width: 350px;
}
#side_bar_outer_sec .MuiDrawer-paper {
 z-index: 9999;
}
.num_top_space {
	padding-top: 30px;
}

#header_outer #burger_menu {
	padding-left: 0;
}

.section.dashboard .col-lg-4 {
	flex: 0 0 auto;
	width: 50%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y);
}

#header_outer .makeStyles-alert-58 {
	position: relative;
	top: -3px;
}

header .MuiButton-label {
	margin: 0;
	padding: 0;
	position: relative;
	top: -3px;
}
}


@media (min-width: 992px) {
	.section.dashboard .col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
		padding-right: calc(var(--bs-gutter-x) * .5);
		padding-left: calc(var(--bs-gutter-x) * .5);
		margin-top: var(--bs-gutter-y);
	}

	#top_heading_stip.m-n-title {
		margin: -20px -20px 20px -20px !important;
	}
	.over_view_tabs .makeStyles-fixedHeight-75 {
	  height: 300px;
    }
	.header_form-select .form-select{
		min-width: 120px;
	}
}


@media (min-width: 1136px) and (max-width: 1920px) {
	.impact_score_hover{
		bottom: 64px !important;
	}
}

@media (min-width: 1024px) and (max-width: 1199px) {
.card-body {
	padding: 0 10px 15px 10px !important;
}
.chart_box_outer {
	overflow: hidden;
}
.chart_box_outer #custom_box,.cricle_chart_outer #custom_box {
	overflow: auto !important;
}	
.cricle_chart_outer  {
	overflow: hidden !important;
}
.section.dashboard table {
	font-size: 12px;
}

#custom_page .card {
	overflow: auto;
}
	#custom_page .cstm-tabs .react-tabs__tab{
		font-size: 12px;
	}
body #inner_box span, body #blue_label {
	font-size: 14px;
}
.most_active_outer .react-tabs__tab-panel {
    min-height: 475px !important;
    overflow-y: auto;
    max-height: 475px !important;
    background: #fff;
}
}

.css-1chpzqh {
	margin: 0!important;
}





@media (min-width: 768px) and (max-width: 1199px) {
.most_active_outer .react-tabs__tab-panel {
	min-height: inherit;
	max-height: inherit;
	padding-bottom: 20px;
}
.most_active_outer .MuiPaper-root {
	height: auto;
}
.over_view_tabs .makeStyles-fixedHeight-74 {
	max-height: 300px;
}
.brand_name.MuiGrid-grid-xs-12.MuiGrid-grid-sm-2 {
	width: 100%;
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
}
.detail_content_outer {
	padding-right: 25px;
}
.MuiGrid-root.detail_content.MuiGrid-grid-xs-12.MuiGrid-grid-sm-5 ,.MuiGrid-root.detail_content.MuiGrid-grid-xs-12.MuiGrid-grid-sm-5{
	width: 50%;
	flex-grow: 0;
	max-width: 50%;
	flex-basis: 50%;
}
.formBrandCheckboxDone .formBrandCheckbox {
	width: 33%;
}	
}
.formBrandCheckboxDone label {
    align-items: center!important;
}
.admin_contact p {
    word-break: break-all;
    width: 100%;
}


@media (max-width: 991px) {
.new_select_sec {
	margin-bottom: -30px;
}
.new_select_outer label {
	min-width: 70px;
}
.new_select_sec .new_select_outer {
	order: 1;
}
.new_select_sec h6 {
	order: 2;
}
.new_select_sec .new_select_outer {
	order: 1;
	flex-wrap: wrap;
	padding-right: 30px;
	margin-bottom: 15px;
}
.new_select_sec div {
	order: 2;
}
.list_num .affiliation-title {
	border-right: 1px solid #cccccc;
	padding-right: 15px;
	margin-right: 15px;
	border-left: none !important;
	padding-left: 0 !important;
	margin-left: 0 !important;
}
#footer_outer p {
	font-size: 13px;
}
.list_num .affiliation-title:last-of-type{
	border-right: none;
	padding-right: 0;
	margin-right: 0;
	border-left: none !important;
	padding-left: 0 !important;
	margin-left: 0 !important;
	}
.profile_text_p {
	flex-wrap: wrap;
}
.kl_pro_listing .value-text {
	padding-top: 5px;
}
#custom_page .MuiPaper-rounded {
	height: auto;
}
.clickable.pro_pick .MuiAvatar-circular{border-radius: 0 !important;}
.clickable.pro_pick {
	background: transparent !important;
	box-shadow: none !important;
}
.btn_right_label {
	width: 100%;
}
.most_active_outer #blue_label {
	font-size: 12px;
	padding: 0 !important;
	max-width: 80px;
	text-align: center;
}
.section.dashboard .card {
	margin-bottom: 30px;
}
.makeStyles-fixedHeight2-66 {
	height: auto;
}
.most_active_outer .cstm-tabs {
	overflow: auto;
}
#custom_page .most_active_outer .MuiPaper-rounded {
	overflow: auto !important;
}
.dash-items.ms-auto.filter_label_style {
	margin-left: inherit !important;
}
.clickable.pro_pick {
	background: transparent;
	box-shadow: -60px 0px 0 #f2f4f8;
}
.new_label {
	display: none;
}
#header_outer .makeStyles-logo-28 {
	max-width: 100px;
}
.dash-items.ms-auto {
	flex-wrap: wrap;
}

.di-date-range select {
	max-width: 274px;
}

.over_view_tabs .makeStyles-fixedHeight-74 {
	max-height: inherit;
}

.dia {
	flex-wrap: wrap;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	color: #222;
}

#top_heading_stip.light-bg {
	flex-wrap: wrap;
}

#custom_page {
	padding-left: 13px;
	padding-right: 13px;
}

#top_heading_stip h1 {
	width: 100%;
	text-align: center;
	margin-bottom: 15px !important;
	color: #000;
	font-weight: 700;
	font-weight: bold;
}

.section.dashboard table {
	font-size: 14px;
}

#top_heading_stip {
	padding: 15px 25px !important;
}

#top_heading_stip .bt-row,
#top_heading_stip .di-brands > strong {
	margin-bottom: 10px;
}

header .MuiButton-label {
	padding-bottom: 0 !important;
}

body #inner_box span,
body #blue_label {
	font-size: 16px;
}

#custom_box {
	overflow-x: auto;
}

#custom_page #text_label {
	margin: 0 !important;
}	
#custom_page .makeStyles-fixedHeight-74 {
	height: auto;
}	
.chart_box_outer {
	overflow: hidden;
}
.chart_box_outer #custom_box,.cricle_chart_outer #custom_box {
	overflow: auto !important;
}	
.most_active_outer .react-tabs__tab-panel {
	min-height: inherit;
	overflow-y: auto;
	max-height: inherit;
	background: #fff;
	padding-bottom: 20px;
}
.cricle_chart_outer  {
	overflow: hidden !important;
}
#top_heading_stip.m-n-title {
	margin: -20px -13px 20px -13px !important;
}	
#outer_pop {
	min-width: inherit;
	max-width:90% !important;
	width: 100%;
}	
.cricle_chart_outer .apexcharts-canvas {
/*  height: 500px !important;*/
} 
}

iframe {
	z-index: -1 !important;
}
.MuiAlert-message {
  color: #05357c !important;
}
.MuiAlert-outlinedInfo{
border-color: #05357c !important;	
}
.MuiAlert-outlinedInfo .MuiAlert-icon {
	color:#05357c ;
}
/*body {
	pointer-events: none;
}
.log_out, #log_in_out,#burger_menu,#side_bar_account button,#log_out,#custom_page .cstm-tabs .react-tabs__tab, #custom_page .leaderboard_box_outer .react-tabs__tab,#tittle_tabs button,#tittle_two_name .lower-tab button,[aria-label="close"],.apexcharts-menu-icon,.filter_label_style,.new_label,.react-tabs__tab-panel{
	pointer-events: all;
}*/
/*=========**********************************Remove css start here********************************===============*/

/*#recharts_measurement_span {
	display: none !important;
}
iframe {
	display: none !important;
}*/
/*=========**********************************Remove css end here********************************===============*/

.new_list_style div .msl-btn.msl-clear-btn {
	position: relative;
	top: 3px;
}
.new_select_outer .msl-close-icn {
	position: relative;
	top: -4px;
}
.new_select_outer .msl {
	height: 45px;
}
.new_select_outer .msl-input-wrp .msl-single-value {
	opacity: 0;
}
.new_select_outer .msl-input-wrp {
	position: relative;
}
.new_select_outer .msl-input-wrp::before {
	content: "Select";
	position: absolute;
	left: 0;
	font-size: 16px;
	top: 5px;
	background: #fff;
	right: 0;
	color: #737373;
	padding-left: 8px;
}

#hiden_box {
	display: none;
}

.Captilize
{
	text-transform: capitalize;
}

/*New header css start here*/
.header_select .css-yk16xz-control,.header_select .css-1pahdxg-control {
	background: #fff;
	border-color: #000 !important;
	min-width: 220px;
	color: #000 !important;
	border-radius: 0;
}
.header_select svg {
	fill: #000;
}
.header_select .css-1wa3eu0-placeholder {
	color: #000;
}
.d-inline-block.header_select:hover:active:focus:focus-visible {
	outline: none;
}
.sub_header {
	display: flex;
	justify-content: space-between;
	padding: 9px 15px 0;
	border-top: 1px solid #234079;
	align-items: center;
}
.sub_header .brandfocusfilters {
	width: 90%;
	display: flex;
	align-items: center;
}
.applied_tags {
	width: 100%;
	overflow-x: auto;
	display: inline-block;
	white-space: nowrap;
	padding-bottom:3px;
}
.sub_header .filtername {
	background: #dbfaff;
	border-radius: 40px;
	padding: 1px 5px 1px;
	color: #170030;
	margin-right: 7px;
	display: inline-block;
	font-size: 12px;
}
#header_outer .sub_header .filtername button {
	padding: 2px;
	background: #002b7d !important;
	color: #ffff;
	position: relative;
    top: -2px;
	margin-left: 5px;
	
}
#header_outer .sub_header .filtername button svg {
	width: 9px;
	height: 9px;
}
/* #header_outer .css-1rhbuit-multiValue {
	display: none;
} */
.applied_filter {
	margin-right: 15px;
	position: relative;
	top: -3px;
}
.applied_filter {
	width: 100%;
	max-width: 95px;
}
.header_select {
	position: relative;
}
.header_select::after {
	/* content: "Selected"; */
	position: absolute;
	top: 9px;
	color: #333;
	left: 10px;
}
.css-26l3qy-menu{
	color: #000;
}
.brand_label_outer p {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 0;
}
.engagements_container .profile_text_p {
	margin-bottom: 7px;
}
.brand_label_outer {
	flex-wrap: wrap;
	width: 100%;
}
.engagements_container .text_label {
	display: flex;
}

.engagements_container .profile_text_p:last-of-type {
	flex-wrap: nowrap;
	margin-top: 0;
}
.new_select_outer .msl {
	border-radius: 0;
}
.email_del {
    align-items: center;
    width: 18%;
    justify-content: center;
    display: flex;
}
.email_del .send_email {
    display: flex;
    align-items: center;
}
.email_del input {
    margin-right: 7px!important;
    width: 14px!important;
}
.email_del label {
    font-weight: 600;
	color: #222;
}
.iconcontainer p.icon_text {
    position: absolute;
    right: 66px;
    white-space: pre-line;
    margin: 0;
    top: -11px;
    width:250px;

}
.iconcontainer {
    width: auto;
    float: right;
    position: relative;
}
img.infoIcon {
    position: relative;
    top: -6px;
    margin-right: 30px;
}
@media (min-width: 768px) {
.new_list_style div .msl-btn.msl-arrow-btn {
	position: relative;
	top: -4px;
	right: 3px;
}
.new_select_sec {
	padding-right: 50px;
}
.my_fav_list {
	position: relative;
	top: -10px;
	max-width: 200px;
}
.new_select_outer .msl {
	height: 42px;
	border-radius: 0;
}
.new_select_outer .right_select {
	max-width: 250px;
}
.new_select_outer .left_select {
	max-width: 250px;
}
.new_select_outer {
	width: 100%;
	display: flex;
	max-width: 100%;
	justify-content: space-between;
	padding-left: 260px;
}
.my_fav_list label {
	margin-left: -15px;
}
.detail_content_sec {
	padding-top: 32px;
}

.custom_container_size.MuiGrid-container {
	flex-wrap: nowrap !important;
}
.engagements_container {
	padding-left: 0px;
}

.kl_pro_listing .value-text {
	min-width: 220px;
}
.kl_pro_leftside .custom_container_size {
	align-items: flex-start !important;
}
.profile_text_p:last-of-type {
	flex-wrap: wrap;
	margin-top: 10px;
}
.profile_text_p .brand_label_outer .brand_label {
	margin-top: 10px;
}
.profile_text_p:last-of-type .brand_label_outer {
	flex-wrap: wrap;
}
.profile_text_p:last-of-type .brand_label_outer .brand_label {
	max-width: 100%;
	margin-right: 0;
}
.custom_container_size.MuiGrid-container {
	flex-wrap: nowrap !important;
}	
.engagements_container .total {
	width: 140px;
}	
}


@media (max-width: 768px) {
.header_select .css-yk16xz-control, .header_select .css-1pahdxg-control {
	min-width: 151px;
}
.applied_filter {
	max-width: 73px;
	font-size: 11px;
	margin-right: 0;
}	
.sub_header .filtername {
	padding: 5px 3px 3px !important;
	margin-right: 2px;
	font-size: 10px;
}	
#header_outer .sub_header .filtername button svg {
	width: 10px;
	height: 10px;
}	
.header_clear_all {
	font-size: 12px;
	width: 140px;
	min-width: 109px;
	cursor: pointer !important;
}
.sub_header .brandfocusfilters {
	width: 87%;
}
.header_select::after {
	top: 10px;
	left: 8px;
	font-size: 12px;
}
.header_select .css-tlfecz-indicatorContainer {
	padding: 4px;
}
.css-26l3qy-menu{
	font-size: 12px;
}
.brand_label_outer .brand_label {
	margin: 5px 0 7px;
}
.engagements_container .total {
	min-width: 100px;
}
.engagements_container .profile_text_p{
	flex-wrap: wrap !important;
}
.engagements_container .title {
	min-width: inherit;;
}
.engagements_container {
	margin-top: 15px;
}
.detail_content {
	margin-top: 15px;
}	
.makeStyles-header_clear_all_2-26 {
	font-size: 12px;
}	
.cricle_chart_outer .apexcharts-canvas {
/*	height: 250px !important;*/
  } 
  .chart_box_outer #custom_page #heading_strip div {
	height: 400px !important;
}
.chart_box_outer .apexcharts-canvas.apexcharts-theme-light{
	height: 520px !important;
}
.chart_box_outer .apexcharts-canvas.apexcharts-theme-light svg{
	height: 520px !important;
}
.chart_box_outer .apexcharts-legend.apexcharts-align-right.position-right {
    top: auto !important;
    right: auto !important;
    bottom: 0px !important;
}
.chart_box_outer .apexcharts-canvas.apexcharts-theme-light foreignObject {
	height: 500px !important;
}

}



.brand_focuses {
	background-color: purple;
}

.dot {
	min-width: 12px;
	min-height: 12px;
}
.makeStyles-header_clear_all_2-26 {position: relative;top:-3px;}

.detail_brand_outer .detail_content p {
    min-height: 22.88px;
}
#top_heading_stip.m-n-title.heading_lavel {
    margin: 0 !important;
    padding: 10px 15px!important;
}
.heading_lavel .custom_label_sec {
    display: none;
}

.popupKols {
	width: 100%;
	display: inline-block;
	text-align: left;
	margin: 20px 0 10px;
	color: #000;
	font-size: 18px;
}

.KolandButton .MuiGrid-root.MuiGrid-container {
	display: flex;
	flex-wrap: nowrap;
}
.KolandButton .MuiSelect-select.MuiSelect-select,.custom-select .MuiSelect-select.MuiSelect-select{
	padding-top: 10px;
	padding-bottom: 10px;
}
.writeinkol {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
}
.writeinkol .add-btn-select {
	background: #05357c !important;
	display: inline-block;
	border-radius: 5px;
	margin-top: 20px;
	padding: 7px 15px !important;
}
.writeinkol .add-btn-select svg {
	fill: #fff !important;
}
.writeinkol .add-btn-select .add-kol-btn {
	color: #fff !important;
	font-size: 14px;
}
.InternalandButton {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.InternalandButton .add-btn-select {
	margin-left: 10px;
}
.InternalandButton .MuiSelect-select.MuiSelect-select,.writeinkol .MuiSelect-select.MuiSelect-select {
	padding-top: 10px;
	padding-bottom: 10px;
}
.popupInternal {
	color: #000;
    font-size: 18px;
    width: 100%;
    display: inline-block;
    padding-bottom: 15px;
	text-align : left;
}
.InternalandButton .MuiGrid-item {
	flex-basis: 50%;
	max-width: 50%;
}
.InternalandButton{
	margin-bottom: 25px;
}
.internal_selects .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1 {
	flex-basis: 22%;
	max-width: 22%;
	text-align: right;
}
.internal_selects .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
	max-width: 38%;
	flex-basis: 38%;
}

.descriptioninput div input{
	width: 100%!important;
}
.descriptioninput {
    width: 100%;
}

.leadership_column .MuiGrid-item {
    max-width: 100% !important;
    flex-basis: 100% !important;
}
.leadership_duplicate .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
    max-width: 75%;
    flex-basis: 75%;
}
.custombox  span.css-1okebmr-indicatorSeparator {
    display: none;
}
.custombox .css-1s2u09g-control {
    height: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.custombox div#react-select-5-placeholder {
    color: #000;
    text-align: left;
}
.custombox .css-1s2u09g-control:hover {
    border-color: #000;
}


.custombox .css-14el2xx-placeholder {
    color: #000;
    display: flex;
    text-align: left;
    white-space: nowrap;
}
.css-lpahdxg-control .css-14el2xx-placeholder {
    position: relative;
    top: -10px;
    display: flex;
    text-align: left;
    white-space: nowrap;
}
.custombox div#react-select-5-placeholder {
    color: #000;
    display: flex;
    text-align: left;
    white-space: nowrap;
}
.custombox div.css-1wa3eu0-placeholder {
	display: none;
}

.custombox:hover div{border-color: #000;}
.custombox svg {
	display: none;
}
.custombox {
	position: relative;
}
.custombox::after {
	/* content: "▼"; */
	content: "";
	position: absolute;
	top: 20px;
	right: 10px;
	font-size: 13px;
	content: '';
	border-top: 6px solid rgba(0, 0, 0, 0.54);
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}
.form-shrink-off .MuiInputLabel-shrink {
	background: #fff;
}
.form-shrink-on .MuiInputLabel-shrink {
	background: #fff;
	z-index:99;
}
.form-shrink-on .custombox {
	z-index:9;
}
.custom_popup_outer .custom-select .space-select .MuiSelect-select.MuiSelect-select#demo-simple-native {
	padding: 11px 15px;
}



body .custombox > div:nth-child(3) > div{
/*	z-index:99999;
	background: red;*/
	/*background: red;*/
	height: auto;
	line-height:1;
	min-height: inherit;
	z-index: 999;
}


.custom_popup_outer #datetime-local {
	height: 45px;
	padding: 0 10px;
}

.custom_popup_outer .space-select.internal_selects.leadership_duplicate {
	margin-bottom: 10px !important;
}
.custom_popup_outer .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14px;
}

.form-shrink-on  .MuiInputLabel-shrink,.custom_popup_outer .MuiInputLabel-shrink{
  transform: translate(14px, -6px) scale(0.75);
  background: #fff;
}

.custombox .css-1s2u09g-control div:first-of-type ,.custombox .css-1pahdxg-control div:first-of-type,.custombox div:first-of-type div:first-of-type{
	height: 43px !important;
	line-height:38px !important;
	min-height: 43px !important;
}
.custombox div:last-of-type  {
	height: auto !important;
	line-height: 1;
	min-height: inherit !important;
}

.custombox .css-1pahdxg-control:hover {
    border-color: #000;
/*	height: 43px;
	line-height: 43px;
	min-height: 43px;
	outline: none;
	border-width: 1px;*/
}

.custom_popup_outer .custom-select .space-select .MuiInputLabel-outlined.MuiInputLabel-shrink{
	background: #fff;
}
.custom_popup_outer .MuiDialogContent-root.MuiDialogContent-dividers .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2{
	margin-bottom: 15px;
}
.custom_popup_outer #outlined-basic,.custom_popup_outer #mui-2,.custom_popup_outer input#mui-1 ,.custom_popup_outer input#mui-3,.custom_popup_outer [type="datetime-local"],.custom_popup_outer [name="Notes_Details__c"]{
	height: 45px;
	padding: 0 15px;
}
.custom_popup_outer #demo-simple-select {
	font-size: 14px;
	padding: 11px 15px;
}
.custom_popup_outer .MuiDialogTitle-root{padding-left: 20px;}

.custom_popup_outer .KolandButton .MuiSelect-select.MuiSelect-select,.custom_popup_outer .custom-select .MuiSelect-select.MuiSelect-select {
	padding-top: 10px;
	padding-bottom: 12px;
}

.header_select div {
    border-radius: 0;
}
.flrx-bar {
    display: flex;
    justify-content: space-between;
}
.flex-btn .MuiButton-text {
    color: #fff;
    background-color: #05357c;
    font-size: 14px;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border-radius: 5px !important;
    font-weight: 700 !important;
    box-shadow: none;
}
.flex-btn .MuiButton-root:hover {
    color: #fff;
    background-color: #05357c;
}
.flex-btn .MuiButton-text {
    margin-right: 16px;
}
.MuiBox-root-699 {
    padding-top: 17px;
}
.MuiBox-root-699 {
    padding-top: 18px!important;

}
.hiddeninput{
	z-index: -999;
	position: relative;
	display: none;
}

.send_invite{
	
	padding-left: 0px!important;
	padding-top: 20px!important;
	
	
}
.send_invite label{
	margin-right: 10px!important;
}
.send_invite input{
	width: 14px!important;
	height: 14px!important;
}
.line_align{
	padding-top: 18px!important;
    padding-left: 16px;
    padding-bottom: 8px;
}
.cv_popup .MuiDialogTitle-root h2{
	text-align: center!important;
}

.cv_backdrop{
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5);
	z-index: 2;
	cursor: pointer;
}

.cv_backdrop > div {
    display: grid;
    height: 100vh;
    place-items: center;
    width: 100vw;
}
.email_buttons .MuiButtonBase-root{
    color: #fff;
    background-color: #05357c;
    font-family: Bilo;
    font-size: 14px;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 5px !important;
    font-weight: 500 !important;
}
.email_buttons .email_buttons {
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.email_buttons .send_invite {
    padding-left: 0px!important;
}
.email_buttons input {
    height: 16px !important;
    width: 16px !important;
}
.send_invite label {
    color: #000;
    margin-right: 10px!important;
    font-weight: 600;
    font-size: 16px;
    margin-left: 16px;
}
.both_buttons button:nth-child(1) {
    margin-right: 36px;
}
.email_buttons {
    display: flex;
    justify-content: center;
	margin-bottom: 20px;
}
.both_buttons button:hover {
	background-color: #05357c;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.req-fild{
	text-align: left;
}
.MuiButton-root.Mui-disabled {
    color:#fff !important;
    opacity: .5;;
}
.custom_popup_outer .custom-select {
    text-align: left;
}

.emailSub{
	margin-top: 0px;
	margin-bottom: 15px;
}
.infoIcon{
	width: 40px;
	float: right;
}
.popupKols.no_space {
    margin-top: 0;
}
.info {
	padding: 6px 8px;
	font: 14px/16px Arial, Helvetica, sans-serif;
	background: white;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
  }
  
  .info h4 {
	margin: 0 0 5px;
	color: #777;
  }
  
  .legend {
	text-align: left;
	line-height: 18px;
	color: #555;
  }
  
  .legend i {
	width: 18px;
	height: 18px;
	float: left;
	margin-right: 8px;
	opacity: 0.7;
  }
  .MuiGrid-root.engagements_container.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-3 .profile_text_p {
    display: flex;
    flex-direction: column;
	gap:2px;
}
.Classification {
    margin: 0!important;
}
@media (max-width: 767px) {
	.iconcontainer p.icon_text {
		right: 8px;
		top: 22px;
		max-width: 200px;
		font-size: 10px;
		
	}
	
	.send_invite label {
		margin-left: 8px;
	}
	img.infoIcon {
		top: -3px;
	}
	.infoIcon {
		width: 30px;
		position: relative;
		top: -3px;
	}
	.email_del .send_email {
		width: 100%;
	}
	.email_del .MuiGrid-root.MuiGrid-item {
		text-align: right !important;
	}
	.email_del .send_email {
		padding-left: 10px;
	}
	.email_del {
		width: 100%;
	}
	.MuiGrid-container .MuiGrid-item{
		position: relative;
	}
	.makeStyles-paper-62 {
		min-height: unset !important ;
	}
	.custom_popup_outer .custom-select .MuiGrid-root.MuiGrid-item {
		text-align: left;
	}
	.MuiButton-root.Mui-disabled {
		color: rgb(255 255 255);
		opacity: .5;;
	}
	.email_buttons {
		flex-wrap: wrap;
		justify-content: space-around;
	}
	.both_buttons button:nth-child(1) {
		margin-right: 14px;
	}
	.send_invite {
		margin-bottom: 9px;}
.flex-btn .MuiButton-text {
		margin-right: 8px;
}
	
.flex-btn .MuiButton-text {
		padding-right: 10px !important;
		padding-left: 8px !important;
}
#logo_outer img {
	max-height: 26px;
}
.KolandButton .add-btn-select {
	padding-left: 5px;
}
.filter_label_style .di-view.dia {
	border: none;
	margin-bottom: 10px;
}
.dash-items.ms-auto.filter_label_style .di-date-range.dia.me-4 {
	width: 50%;
	float: left;
	margin: 0 !important;
	padding: 0;
}
#burger_menu {
	position: relative;
	top: 10px;
	left: -10px;
}
.dash-items.ms-auto.filter_label_style .di-date-range.dia.me-4:first-of-type{
	padding-right: 10px;
}
.dash-items.ms-auto.filter_label_style .di-date-range.dia.me-4:last-of-type {
	margin-top: 21px !important;
	padding-left: 10px;
}	
body .sub_header .filtername {
	padding: 2px 2px 1px !important;
	margin-right: 2px;
	font-size: 10px;
}	
body .Calendar-right-side h2.fc-toolbar-title {
	font-size: 14px;
}
body  .fc .fc-button-group > .fc-button {
	padding: 3px 4px;
	font-size: 12px;
}
body .Calendar-right-side button.fc-button.fc-button-primary {
	padding: 3px 4px;
	font-size: 12px;
	min-height: 25px;
}
body .fc .fc-daygrid-event-harness div {
	font-size: 10px;
}
.custom_popup_outer  .popupalignment .MuiGrid-root.MuiGrid-item {
	flex-basis: 100%;
	-webkit-box-flex: 0;
	max-width: 100%;
	margin-bottom: ;
	margin: 0 0 10px !important;
}
.custom_popup_outer  .KolandButton .MuiGrid-root.MuiGrid-container {
	display: flex;
	flex-wrap: wrap;
}
.custom_popup_outer  .KolandButton .MuiGrid-root.MuiGrid-item {
	flex-basis: 100%;
	-webkit-box-flex: 0;
	max-width: 100%;
	margin: 0 0 10px !important;
}
.custom_popup_outer  .InternalandButton {
	flex-wrap: wrap;
}
.custom_popup_outer  .InternalandButton .MuiGrid-root.MuiGrid-item {
	flex-basis: 100%;
	-webkit-box-flex: 0;
	max-width: 100%;
	margin: 0 0 10px !important;
}	
.custom_popup_outer .MuiGrid-root.MuiGrid-item {
	max-width: 100%;
	flex-basis: 100%;
	margin: 0 0 5px;
	text-align: right;
}
.custom_popup_outer .MuiSelect-select.MuiSelect-select {
	text-align: left;
}
.custom_popup_outer .MuiDialogContent-root.MuiDialogContent-dividers{
	padding: 15px 15px 15px;
}
	
.internal_selects .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
	max-width: 100%;
	flex-basis: 100%;
}
.internal_selects .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1 {
	flex-basis: 100%;
	max-width: 100%;
	text-align: right;
}	
.custom_popup_outer .MuiGrid-root.MuiGrid-item .MuiFormControl-root.MuiTextField-root {
	width: 100%;
}	
.custom_popup_outer .MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -9px) scale(0.75);
	background: #fff;
}	
body .fc .fc-daygrid-event-harness div:hover {
	color: #ccc;
}	
.custom_popup_outer .InternalandButton .MuiButtonBase-root {
	margin-left: 0;
	padding-left: 0;
}
.custom_popup_outer .MuiDialogTitle-root {
	padding-left: 15px;
}	
.inside_popup .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 14px 15px;
	line-height: 1;
	font-size: 12px;
}	
.inside_popup .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
	font-size: 12px;
	transform: translate(14px, 12px) scale(1);
}
.inside_popup .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
}
.inside_popup .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item .MuiFormControl-root.MuiTextField-root {
	width: 100%;
}
.inside_popup  .MuiSelect-select.MuiSelect-select {
	font-size: 12px;
}
.writeinkol .add-btn-select {
	margin: 10px 0;
}
.filter_label_style .di-view.dia {
	border: none;
	margin-bottom: 10px;
}
.btn_right_label {
	justify-content: inherit;
}
.filter_label_style .di-view.dia {
	border: none;
	margin-bottom: 10px;
}
.fc-toolbar-chunk .fc-button-group {
	max-width: 100% !important;
}
.fc-button-group {
	max-width: 46px !important;
}
/*	Calendar css start //
body .Calendar-right-side .fc-toolbar-chunk:last-of-type .fc-button-group button.fc-button.fc-button-primary {
	max-width: inherit !important;
	margin: 0 2px;
}
body .Calendar-right-side .fc-toolbar-chunk:last-of-type .fc-button-group {
	width: 100%;
}

body .Calendar-right-side .fc-toolbar-chunk:last-of-type {
	order: 2;
	width: 50%;
}
body .Calendar-right-side .fc-toolbar-chunk .fc-toolbar-title {
	text-align: center;
	margin-top: 13px;
}
body .Calendar-right-side .fc-toolbar-chunk:first-of-type {
	order: 1;
	width: 50%;
}
.fc .fc-toolbar-title {
	font-size: 1.75em;
	margin: 0;
}
body .Calendar-right-side .fc-toolbar-chunk {
	order: 3;
	width: 100%;
}
body .Calendar-right-side .fc .fc-toolbar {
  flex-wrap: wrap;
}
#custom_page .MuiPaper-rounded {
	min-height: inherit;
}
.calendar-container-style {
	padding: 0;
	margin: 0 -10px;
}*/
.dash-items.ms-auto.filter_label_style.custom_label_sec {
	width: 100%;
}
#footer_outer p {
	font-size: 10px;
}
#custom_page .btn_right_label {
	justify-content: flex-start;
}
.sort_type_inner label {
	min-width: 45px;
}
.results_num {
	padding-right: 5px;
}
.sort_icons .btn {
	margin: 0 0 0 7px;
}
.director_text {
	margin-bottom: 5px;
}	
.list_num .affiliation-title {
	padding: 0 !important;
	border: none !important;
	width: 100%;
	display: inline-block;
	margin-bottom: 5px !important;
}
.detail_content.detail_content_sec {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
}	
.engagement_list {
	flex-wrap: wrap;
}
.engagemen_brand {
	white-space: normal;
	flex-wrap: wrap;
}
body .engagement_label_name {
	padding-left: 15px;
	margin-left: 0;
	border-left: none;
	margin-top: 2px;
}
.engagement_list .brand_label .dot {
	margin-left: 0;
	margin-top: 2px;
	margin-right: 3px;
}
.engagement_user_list li .profile_name {
	padding-left: 0 !important;
	padding-right: 5px !important;
}
	
#top_heading_stip.institutions_top_strip {
	padding: 15px !important;
}
.report_table_list th , 
.report_table_list td {
	line-height: 1.2;
	font-size: 13px;
}
.resources-body-title {
	font-size: 14px !important;
	padding: 0 5px;
	line-height: 1.2;
}
.resources-img-container {
	max-width: 50px;
}	
.resources-img-container img {
	max-width: 50px;
}
#custom_page #download_btn a {
	font-size: 12px;
}
.resources-img-container svg {
	max-width: 50px !important;
	height: 50px !important;
}
.new_select_outer .msl {
	height: 42px;
}	
.new_select_outer label {
	min-width: 75px;
	font-size: 13px;
}
.support-header {
	padding: 0 0px 10px;
}
.sort_type_inner .form-select{
	min-width: 135px;
}
body #top_heading_stip.top_strip_head{flex-wrap: wrap;}
.leadership_duplicate .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
    max-width: 100%;
    flex-basis: 100%;
}
.header_select{
	min-width:151px;
	max-width: 151px;
}
.MuiAccordionSummary-root {
    max-height: unset;

}
.cricle_chart_outer .apexcharts-canvas {
  /*  min-height: 230px;*/
}
.Calendar-right-side .css-1wmc9yg .fc-toolbar-chunk:nth-child(3) .fc-button-group button {
    margin-left: auto !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
	width: auto !important;

}
.Calendar-right-side .css-1wmc9yg .fc-button-group {
    width: 48px;
}
.Calendar-right-side .css-1wmc9yg .fc-toolbar-chunk:nth-child(3) .fc-button-group {
	width: 127px !important;
}
.Calendar-right-side  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    margin-top: 32px;
}
.Calendar-right-side  .MuiGrid-item {
    position: relative;
}
.Calendar-right-side .css-1wmc9yg .fc-toolbar-chunk:nth-child(2){
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}
.most_new_outer .MuiListItem-gutters {
    padding-left: 3px;
    padding-right: 3px;
}
.most_active_outer.most_new_outer .MuiGrid-item:first-of-type {
    font-weight: 500;
    max-width: 55px;
    line-height: 12px;
    padding-right: 5px !important;
}
.most_active_outer.most_new_outer .MuiGrid-root:last-of-type{padding-left: 5px;}
.most_active_outer.most_new_outer 
 .MuiGrid-root {
    line-height: 12px;
    max-width: 450px;
}
.most_new_outer .MuiGrid-container .MuiGrid-item {
    position: relative;
    line-height: 12px;
}
.most_active_outer.most_new_outer .react-tabs__tab-panel ul.MuiList-root.MuiList-padding:first-of-type  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4:first-of-type {
	max-width: 80px;
}
.most_active_outer.most_new_outer #blue_label {
    font-size: 12px;
    padding: 0 !important;
    max-width: 66px;
    text-align: center;
}
.most_active_outer.most_new_outer .MuiListItemAvatar-root {
    min-width: 34px;
}
}

.toolbg{
	height: 400px;
	overflow-y: auto;
}
.fc-timegrid-event {
    min-width: auto!important;
	
}
.CalendarLegendmodal{
	top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
	z-index: 5689878787;
}
.chart_box_outer .impact_score_content {
    min-height: 168px;
}

.chart_box_outer .engagementindex {
    min-height: 168px;
}
.chart_box_outer .custom_chart_sec .apexcharts-legend.apexcharts-align-center.position-bottom .apexcharts-legend-series {
    display: block;
    width: 100%;
    text-align: center;
}

.detail_brand_outer .detail_list {
    height: auto;
}
.for_height .MuiPaper-root.MuiPaper-rounded,.cricle_chart_outer .for_height .MuiPaper-root.MuiPaper-rounded{
    min-height: 570px;
}
span.knowledge_method,span.impact-method {
    font-weight: 600;
}
.for_height .MuiPaper-root {
    height: auto;
}
.kl_pro_listing .indexScore_value {
	font-size: 18px;
} 
.detail_content.detail_content_sec .pt_top {
	width: 100%;
	max-width: 100%;
	text-align: ;
}
.custom_chart_sec .knowlege_index:before, 
.custom_chart_sec .impact_score_hover:before, 
.custom_chart_sec .engagementindex:before, 
.custom_chart_sec .impact_score_content:before {
	display: none;   
}
.chart_box_outer.for_height .react-tabs__tab:focus::after {
    background: #05357c;
    /* bottom: 0; */
}
.chart_box_outer.for_height{overflow: hidden;}
.impact_score_hover.new_impact {
    bottom: 76px !important;
}
.knowlege_index.new_knowledge {
	bottom: 132px !important;
}
.chart_box_outer .custom_chart_sec .apexcharts-legend.apexcharts-align-center.position-bottom .apexcharts-legend-series {
    text-align: left;
	display: flex;
    align-items: center;
	margin-bottom: 7px !important;
}
 
.chart_box_outer .custom_chart_sec .apexcharts-legend.apexcharts-align-center.position-bottom {
    max-width: 215px !important;
    text-align: left !important;
    margin: 0 auto;
}
.chart_box_outer .custom_chart_sec 
 span.apexcharts-legend-marker {
    border-radius: 0 !important;
}
.chart_box_outer .custom_chart_sec .first_chart .apexcharts-legend.apexcharts-align-center.position-bottom {
    max-width: 170px !important;
	padding: 0;
}
.chart_box_outer .custom_chart_sec .second_chart .apexcharts-legend.apexcharts-align-center.position-bottom {
    max-width: 190px !important;
}
.custom_chart_sec .infoIcon {
    width: 22px;
	top: 0px;
	z-index: 6;
}
div#blue_label {
    position: relative;
}
@media (max-width: 767px) {
.custom_chart_sec div#blue_label {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
	padding: 0 10px !important;
}

.chart_box_outer .custom_chart_sec .impact_score_content ,.chart_box_outer .custom_chart_sec .knowlege_index,.chart_box_outer .custom_chart_sec .engagementindex {
    min-height: inherit;
}
.impact_score_hover.new_impact {
    bottom: 109px !important;
}
.knowlege_index.new_knowledge {
    bottom: 72px !important;
}
.chart_box_outer .custom_chart_sec .apexcharts-canvas.apexcharts-theme-light foreignObject {
    height: 207px !important;
}
.chart_box_outer .custom_chart_sec 
 .apexcharts-legend.apexcharts-align-center.position-bottom {
    width: 100% !important;
}
.chart_box_outer .custom_chart_sec .apexcharts-canvas.apexcharts-theme-light,.chart_box_outer .custom_chart_sec .apexcharts-canvas.apexcharts-theme-light svg{
    height:220px !important;
}
/* .profile_detail_page .detail_brand_outer .detail_content_outer {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
.profile_detail_page .detail_brand_outer .detail_content_outer .MuiGrid-grid-xl-4 {
    max-width: 100%;
    flex-basis: 100%;
}
.profile_detail_page .detail_content p strong {
    min-width: 50%;
}
.profile_detail_page .MuiGrid-container .MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%;
} */
.knowlege_index {
	left: 0;
}
.custom_chart_sec div#blue_label {
    margin-bottom: 16px;
}
.engagementindex {
    left: 0;
}
.impact_score_content {
    left: 0;
}
.custom_chart_sec h3 {
    display: flex;
    justify-content: space-between;
}
.custom_chart_sec .infoIcon {
    margin-right: 0;
}
.react-tabs__tab strong{
    width: calc(100% - 12px);
}
}
.chart_box_outer .react-tabs__tab {
    display: flex;
    padding: 9px 12px;
    width: 100%;
    text-align: center;
    justify-content: center;
	align-items: baseline;
}
.chart_box_outer  .react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
}
#custom_page .chart_box_outer  .react-tabs__tab--selected {
    background: unset;
    border-bottom: 4px solid #0f3178 !important;
    border: none;
    border-color: unset;
    color: #0f3178;
    border-radius: unset;
}
.chart_box_outer .react-tabs__tab p{
	margin: 0px;

}
@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .knowlege_index {
		bottom: 136px !important;
	}
	.impact_score_hover.new_impact {
		bottom: 65px !important;
	}
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
    .impact_score_hover.new_impact {
		bottom: 62px !important;
	}
	.impact_score_hover {
		bottom: 65px !important;
	}

}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
   
	.impact_score_hover {
		bottom: 62px !important;
	}
	.impact_score_hover.new_impact {
		bottom: 63px !important;
	}

	/* .knowlege_index {
		bottom: 135px !important;
	} */
}
@media only screen and (min-width: 991px) and (max-width: 1199px) {
   
	.impact_score_hover {
		bottom: 39px !important;
	}
	.knowlege_index.new_knowledge {
		bottom: 110px !important;
	}
	.impact_score_hover.new_impact {
		bottom: 64px !important;
	}
	.knowlege_index {
		bottom: 132px !important;
	}
}
@media only screen and (min-width:768px) and (max-width: 990px) {
   
	.knowlege_index {
		bottom: 164px !important;
	}
	.impact_score_hover.new_impact {
		bottom: 92px !important;
	}
	.impact_score_hover {
		bottom: 58px !important;
	}
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
	.custom_chart_sec h3 {
		font-size: 14px;

	}
	.custom_chart_sec .infoIcon {
		width: 16px;
	}
	
   }

   @media only screen and (max-width: 767px) {
	.knowlege_index.new_knowledge {
		bottom: 108px !important;
	}
	/* .knowlege_index {
		bottom: 110px !important;
	} */
	.knowlege_index.new_knowledge_item{bottom: 110px !important;}
	.impact_score_hover.new_impact {
		bottom: 40px !important;
	}
	
  }
